import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { Color } from '../../../assets/constants';
import { icons } from '../../../assets/icons';
import parse from 'html-react-parser';

interface QNA {
  question: string;
  answer: string;
}

const questionsAndAnswers: QNA[] = [
  {
    question: 'Какъв е материалът на тениските?',
    answer:
      'Нашите тениски са изработени от висококачествен памук (100% - 180 g/m² ринг спун памук) за по-голяма устойчивост и комфорт. Всеки продукт е обозначен с информация за материала.'
  },
  {
    question: 'Как се перат тениските с щампи, за да не се увреди дизайнът?',
    answer:
      'Препоръчваме пране на ръка или в перална машина на ниска температура (<span>от 30°C до 40°C</span>) с обърната наопаки тениска. <span>Избягвайте сушилня и гладете от обратната страна</span>.'
  },
  {
    question: 'Могат ли щампите да се персонализират?',
    answer:
      'Да, предлагаме услуги за персонализация! Можете да изпратите свой дизайн, снимка или текст и ние ще го отпечатаме върху тениска.'
  },
  {
    question: 'Какъв е срокът за доставка?',
    answer:
      'Стандартният срок за доставка е между 1 и 3 работни дни. Ако поръчката е персонализирана, тоест имате по-сложен дизайн, срокът може да е по-дълъг – до 7 работни дни.'
  },
  {
    question: 'Издържат ли щампите на многократно пране?',
    answer:
      'Да, нашите щампи са направени с висококачествени мастила и технологии за печат <a href="https://en.wikipedia.org/wiki/Direct-to-film_printing#:~:text=Direct%2Dto%2Dfilm%20printing%20(,the%20design%20to%20a%20garment." target="_blank">DTF</a>, които гарантират дълготрайност. При правилна грижа те ще останат ярки и в отлично състояние.'
  },
  {
    question: 'Имате ли тениски във всички размери?',
    answer:
      'Да, предлагаме размери от XS до 3XL, а някои модели са налични и в по-големи размери. Моля, проверете таблицата с размери преди поръчка.'
  },
  {
    question: 'Какъв тип печат използвате за щампите?',
    answer:
      'Използваме DTF (Direct-to-Film) печат, който осигурява ярки и устойчиви цветове, подходящи за всякакви материи. Тази технология гарантира дълготрайност и високо качество на щампата при ръчни и машинни пранета на щадящ режим.'
  },
  {
    question: 'Има ли гаранция за качеството на щампите?',
    answer:
      'Да, ако след получаване на продукта има дефект в изработката или проблем с щампата, можете да се свържете с нас в рамките на 14 дни след получаване на продукта, за да го заменим или възстановим сумата.'
  },
  {
    question: 'Мога ли да върна или заменя тениска, ако размерът не ми пасне?',
    answer:
      'За съжаление, <span>няма как</span> да върнете или замените тениска с щампа. Тениските се изготвят персонално за всеки един клиент. Моля да обърнете внимание на таблицата с размерите преди поръчка. :)'
  },
  {
    question: 'Какви са цените за тениски с щампи?',
    answer:
      'Цената за тениска с щампа е 30лв, ако желате да добавим допълнителна щампа се доплаща <span>5лв</span>.'
  },
  {
    question: 'Имате ли отстъпка за по-голямо количество?',
    answer:
      'При поръчка на 10 тениски получавате автоматична отстъпка от 10%. Ако желаете изработка над 50 броя, моля свържете се с нас.'
  },
  {
    question:
      'Вярно ли е, че при поръчка на място на щанда Ви в Делта Планет Мол, можете да ми изработите тениска за 15 минути?',
    answer:
      'Да, вярно е! Можем да Ви изработим напълно персонализирана тениска в <span>рамките</span> на 15 минути. Заповядайте, за да се уверите!'
  }
];

export const FAQAccordion = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const contentRefs = useRef<(HTMLDivElement | null)[]>([]);

  const toggleAccordion = (index: number) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <Wrapper>
      {questionsAndAnswers.map((item, index) => (
        <FAQItem key={index} onClick={() => toggleAccordion(index)}>
          <QuestionWrapper isOpen={activeIndex === index}>
            <ChevronIcon isOpen={activeIndex === index}>
              <icons.FaChevronDown color={Color.DARK_GRAY} size={16} />
            </ChevronIcon>
            {item.question}
          </QuestionWrapper>
          <AnswerWrapper
            isOpen={activeIndex === index}
            ref={(el) => (contentRefs.current[index] = el)}
          >
            {parse(item.answer)}
          </AnswerWrapper>
        </FAQItem>
      ))}
    </Wrapper>
  );
};

const FAQItem = styled.div`
  margin: 5px 0;
  cursor: pointer;
`;

const ChevronIcon = styled.div<{ isOpen: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
  transform: rotate(${({ isOpen }) => (isOpen ? '-180deg' : '0deg')});
`;

const QuestionWrapper = styled.button<{ isOpen: boolean }>`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 1rem;
  font-size: 1.2rem;
  color: ${Color.DARK_GRAY};
  background: ${({ isOpen }) => (isOpen ? Color.LIGHT_GRAY : 'none')};
  border-radius: 10px;
  cursor: pointer;
  outline: none;
  text-align: left;

  &:hover {
    background-color: ${Color.LIGHT_GRAY};
  }

  ${({ isOpen }) =>
    isOpen &&
    `
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `}
`;

const AnswerWrapper = styled.div<{ isOpen: boolean }>`
  overflow: hidden;
  transition: max-height 0.2s ease, opacity 0.3s ease-in-out, padding 0.3s ease;
  max-height: ${({ isOpen }) => (isOpen ? '200px' : '0')};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  padding: ${({ isOpen }) =>
    isOpen ? '1rem 1rem 1rem calc(1rem + 26px)' : '0 1rem 0 calc(1rem + 26px)'};
  background-color: ${Color.LIGHT_GRAY};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  span {
    font-weight: bold;
  }
`;

const Wrapper = styled.div`
  padding: 1.5rem;
`;

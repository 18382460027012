import {
  ImageDetails,
  ImageInfo,
  TShirtColor
} from '../../containers/adminPanel/utils';

export enum TShirtType {
  MEN = 'men',
  WOMEN = 'women',
  KIDS = 'kids',
  OVERSIZED = 'oversized'
}

export interface TShirtSizes {
  men: TShirtSize[];
  women: TShirtSize[];
  kids: TShirtSize[];
  oversized: TShirtSize[];
}

export enum TShirtSize {
  XS = 'XS',
  S = 'S',
  M = 'M',
  L = 'L',
  XL = 'XL',
  XLPlus = 'XL+',
  XXL = 'XXL',
  XXXL = 'XXXL',
  K98 = 'K98',
  K110 = 'K110',
  K122 = 'K122',
  K132 = 'K132',
  K144 = 'K144',
  K156 = 'K156',
  K168 = 'K168'
}

export interface ImagesMen {
  white: ImageInfo;
  black: ImageInfo;
  red: ImageInfo;
  darkBlue: ImageInfo;
  lightBlue: ImageInfo;
  darkGreen: ImageInfo;
  yellow: ImageInfo;
}

export interface ImagesWomen {
  white: ImageInfo;
  black: ImageInfo;
  lightPink: ImageInfo;
}

export interface ImagesKids {
  white: ImageInfo;
  black: ImageInfo;
  red: ImageInfo;
  blue: ImageInfo;
  yellow: ImageInfo;
  lightPink: ImageInfo;
}

export interface ImagesOversized {
  white: ImageInfo;
  black: ImageInfo;
}

export type ProductImages = ImageDetails;
export type Thumbnail = {
  name: string;
  url: string;
  color: TShirtColor;
  type: TShirtType;
};

export interface Product {
  id: string;
  createdAt: string;
  title: string;
  description: string;
  thumbnail: Thumbnail;
  images: ProductImages;
  price: number;
  sizes: TShirtSizes;
  labels: string[];
}

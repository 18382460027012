import {
  translateColorToBulgarian,
  translateTypeToBulgarian
} from '../components/features/cart/utils';
import { OrderShippingInfo } from '../components/features/checkout/utils';
import { mapSizeToString } from '../containers/adminPanel/utils';
import { CartProduct } from '../domain/mappers/cartProductMapper';
import { MyPosSuccessData } from '../hooks/useOrders';
import { PromoCode } from '../hooks/usePromoCodes';
import { ShippingData } from '../hooks/useShipping';

export enum OrderEmailType {
  ADMIN_ORDER = 'ADMIN_ORDER',
  CUSTOMER_ORDER = 'CUSTOMER_ORDER'
}

export interface EmailProps {
  to?: string;
  subject: string;
  text: string;
  html: string;
}
export type EmailOrderSuccessProps = EmailProps &
  MyPosSuccessData & { emailType: OrderEmailType };

export const sendOrderEmailToCustomer = async (
  callback: (props: EmailOrderSuccessProps) => Promise<void>,
  myPosSuccessData: MyPosSuccessData,
  orderNumber: string,
  shippingInfo: OrderShippingInfo
) => {
  const emailProps: EmailOrderSuccessProps = {
    ...myPosSuccessData,
    to: shippingInfo.email,
    subject: 'Поръчка от Teniski Varna',
    text: 'Благодарим ви, че избрахте нашите продукти!',
    html: `
      <p>Благодарим ви, че избрахте нашите продукти!</p>
      <p>Информация за доставка:</p>
      <p>Име: ${shippingInfo.firstName} ${shippingInfo.lastName}</p>
      ${
        shippingInfo.speedyOffice
          ? `<p>Офис на Speedy: ${shippingInfo.speedyOffice}</p>`
          : ''
      }
      ${
        shippingInfo.personalAddress
          ? `<p>Личен адрес: ${shippingInfo.personalAddress}</p>`
          : ''
      }
      <p>Телефон: ${shippingInfo.phone}</p>
    `,
    emailType: OrderEmailType.CUSTOMER_ORDER
  };

  await callback(emailProps);
};

export const sendOrderEmailToAdmin = async (
  callback: (props: EmailOrderSuccessProps) => Promise<void>,
  myPosSuccessData: MyPosSuccessData,
  orderNumber: string,
  shippingInfo: OrderShippingInfo,
  items: CartProduct[],
  promoCode: PromoCode | null,
  totalPrice: number,
  shipping: ShippingData
) => {
  const emailProps: EmailOrderSuccessProps = {
    ...myPosSuccessData,
    subject: `Нова поръчка номер ${orderNumber} на обща стойност ${totalPrice.toFixed(
      2
    )} лв.`,
    text: `Нова поръчка номер ${orderNumber} на обща стойност ${totalPrice.toFixed(
      2
    )} лв.`,
    html: generateAdminOrderEmailHtml(
      orderNumber,
      shippingInfo,
      items,
      promoCode,
      totalPrice,
      shipping
    ),
    emailType: OrderEmailType.ADMIN_ORDER
  };

  await callback(emailProps);
};

export const sendOrderErrorEmailToAdmin = async (
  callback: (props: EmailProps) => Promise<void>,
  shippingInfo: OrderShippingInfo,
  items: CartProduct[],
  promoCode: PromoCode | null,
  totalPrice: number,
  shipping: ShippingData,
  error: boolean
) => {
  const emailProps: EmailProps = {
    subject: 'Неуспешна поръчка от Teniski Varna',
    text: 'Неуспешна поръчка',
    html: generateAdminOrderEmailHtml(
      '',
      shippingInfo,
      items,
      promoCode,
      totalPrice,
      shipping,
      error
    )
  };

  await callback(emailProps);
};

export const sendFeedbackEmailToAdmin = async (
  callback: (props: EmailProps) => Promise<void>,
  feedback: string,
  name: string,
  email: string,
  phone: string
) => {
  const emailProps: EmailProps = {
    subject: 'Обратна връзка от Teniski Varna',
    text: 'Нова обратна връзка от клиент',
    html: generateFeedbackHtml(name, email, phone, feedback)
  };
  await callback(emailProps);
};

const generateAdminOrderEmailHtml = (
  orderNumber: string,
  shippingInfo: OrderShippingInfo,
  items: CartProduct[],
  promoCode: PromoCode | null,
  totalPrice: number,
  shipping: ShippingData,
  error?: boolean
) => {
  let productTable = `
    <div style="overflow: auto; width: 100%;">
      <table style="border: 1px solid #dededf; height: 100%; width: 100%; table-layout: fixed; border-collapse: collapse; border-spacing: 1px; text-align: left;">
        <thead>
          <tr>
            <th
              colspan="6"
              style="text-align: center; border: 1px solid #dededf; background-color: #eceff1; color: #000000; padding: 5px;"
            >
              Поръчка
            </th>
          </tr>
          <tr>
            <th style="border: 1px solid #dededf; background-color: #eceff1; color: #000000; padding: 5px;">Продукт</th>
            <th style="border: 1px solid #dededf; background-color: #eceff1; color: #000000; padding: 5px;">Модел</th>
            <th style="border: 1px solid #dededf; background-color: #eceff1; color: #000000; padding: 5px;">Цвят</th>
            <th style="border: 1px solid #dededf; background-color: #eceff1; color: #000000; padding: 5px;">Размер</th>
            <th style="border: 1px solid #dededf; background-color: #eceff1; color: #000000; padding: 5px;">Персонализация</th>
            <th style="border: 1px solid #dededf; background-color: #eceff1; color: #000000; padding: 5px;">Цена</th>
          </tr>
        </thead>
        <tbody>
  `;

  items.forEach((item) => {
    productTable += `
      <tr>
        <td style="border: 1px solid #dededf; background-color: #ffffff; color: #000000; padding: 5px;">${
          item.title
        }</td>
        <td style="border: 1px solid #dededf; background-color: #ffffff; color: #000000; padding: 5px;">${translateTypeToBulgarian(
          item.type
        )}</td>
        <td style="border: 1px solid #dededf; background-color: #ffffff; color: #000000; padding: 5px;">${translateColorToBulgarian(
          item.color
        )}</td>
        <td style="border: 1px solid #dededf; background-color: #ffffff; color: #000000; padding: 5px;">${mapSizeToString(
          item.size
        )}</td>
        <td style="border: 1px solid #dededf; background-color: #ffffff; color: #000000; padding: 5px;">${
          item.customizationNotes || 'Няма'
        }</td>
        <td style="border: 1px solid #dededf; background-color: #ffffff; color: #000000; padding: 5px;">${item.price.toFixed(
          2
        )}лв</td>
      </tr>
    `;
  });

  productTable += `
        </tbody>
      </table>
    </div>
  `;

  const priceTable = `
    <div style="overflow: auto; width: 100%; margin-top: 20px;">
      <table style="border: 1px solid #dededf; height: 100%; width: 100%; table-layout: fixed; border-collapse: collapse; border-spacing: 1px; text-align: left;">
        <thead>
          <tr>
            <th
              colspan="3"
              style="text-align: center; border: 1px solid #dededf; background-color: #eceff1; color: #000000; padding: 5px;"
            >
              Цена
            </th>
          </tr>
          <tr>
            <th style="border: 1px solid #dededf; background-color: #eceff1; color: #000000; padding: 5px;">Доставка</th>
            <th style="border: 1px solid #dededf; background-color: #eceff1; color: #000000; padding: 5px;">Промо код</th>
            <th style="border: 1px solid #dededf; background-color: #eceff1; color: #000000; padding: 5px;">Общо</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style="border: 1px solid #dededf; background-color: #ffffff; color: #000000; padding: 5px;">${
              totalPrice >= shipping.minimumAmount
                ? `N/A (${totalPrice.toFixed(2)} >= ${shipping.minimumAmount})`
                : `${shipping.shippingCost}лв`
            }</td>
            <td style="border: 1px solid #dededf; background-color: #ffffff; color: #000000; padding: 5px;">${
              promoCode
                ? `${promoCode.name} (${promoCode.percentage}%)`
                : 'Няма'
            }</td>
            <td style="border: 1px solid #dededf; background-color: #ffffff; color: #000000; padding: 5px;">${totalPrice.toFixed(
              2
            )}лв</td>
          </tr>
        </tbody>
      </table>
    </div>
  `;

  const shippingTable = `
    <div style="overflow: auto; width: 100%; margin-top: 20px;">
      <table style="border: 1px solid #dededf; height: 100%; width: 100%; table-layout: fixed; border-collapse: collapse; border-spacing: 1px; text-align: left;">
        <thead>
          <tr>
            <th
              colspan="4"
              style="text-align: center; border: 1px solid #dededf; background-color: #eceff1; color: #000000; padding: 5px;"
            >
              Доставка
            </th>
          </tr>
          <tr>
            <th style="border: 1px solid #dededf; background-color: #eceff1; color: #000000; padding: 5px;">Име</th>
            <th style="border: 1px solid #dededf; background-color: #eceff1; color: #000000; padding: 5px;">Телефон</th>
            <th style="border: 1px solid #dededf; background-color: #eceff1; color: #000000; padding: 5px;">Имейл</th>
            <th style="border: 1px solid #dededf; background-color: #eceff1; color: #000000; padding: 5px;">${
              shippingInfo.speedyOffice ? 'Офис на Speedy' : 'Личен адрес'
            }</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style="border: 1px solid #dededf; background-color: #ffffff; color: #000000; padding: 5px;">${
              shippingInfo.firstName
            } ${shippingInfo.lastName}</td>
            <td style="border: 1px solid #dededf; background-color: #ffffff; color: #000000; padding: 5px;">${
              shippingInfo.phone
            }</td>
            <td style="border: 1px solid #dededf; background-color: #ffffff; color: #000000; padding: 5px;">${
              shippingInfo.email
            }</td>
            <td style="border: 1px solid #dededf; background-color: #ffffff; color: #000000; padding: 5px;">${
              shippingInfo.speedyOffice ?? shippingInfo.personalAddress
            }</td>
          </tr>
        </tbody>
      </table>
    </div>
  `;

  const emailHtml = `
    <h1>Поръчка номер ${orderNumber}</h1>
    ${productTable}
    ${priceTable}
    ${shippingTable}
  `;

  const errorHtml = `
    <h1 style="color: red">Възникна проблем при плащането и поръчката не беше успешна</h1>
    ${emailHtml}
  `;

  return error ? errorHtml : emailHtml;
};

const generateFeedbackHtml = (
  name: string,
  email: string,
  phone: string,
  feedback: string
) => `
  <body style="color: #000">
    <h1><strong>Обратна връзка от клиент</strong></h1>
    <p>Име: <strong>${name === '' ? 'Не е посочено' : name}</strong></p>
    <p>Имейл: <strong>${email === '' ? 'Не е посочен' : email}</strong></p>
    <p>Телефон: <strong>${phone === '' ? 'Не е посочен' : phone}</strong></p>
    <p>Съобщение:</p>
    <h2>${feedback}</h2>
  </body>
`;

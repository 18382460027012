import React, { useLayoutEffect, useState } from 'react';
import * as MyPOSEmbedded from 'mypos-embedded-checkout';
import { v4 as uuid4 } from 'uuid';
import {
  mapCartItemsToMyPosProduct,
  MyPosProduct
} from '../domain/mappers/cartProductMapper';
import { CheckoutContainer } from '../containers/Checkout/CheckoutContainer';
import {
  getDiscountedPrice,
  getTotalPrice,
  OrderShippingInfo
} from '../components/features/checkout/utils';
import { ShippingData, useShipping } from '../hooks/useShipping';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { icons } from '../assets/icons';
import { Color } from '../assets/constants';
import { PromoCode } from '../hooks/usePromoCodes';
import { scrollToTop } from '../utils/scrollToTop';
import { SummaryContainer } from '../containers/Checkout/SummaryContainer';
import { useCustomNavigate } from '../hooks/useCustomNavigate';
import { useProducts } from '../hooks/useProducts';
import { useDiscounts } from '../hooks/useDiscounts';
import { useAppSelector } from '../hooks/useRedux';
import { useMailgun } from '../hooks/useMailgun';
import {
  sendOrderEmailToAdmin,
  sendOrderEmailToCustomer,
  sendOrderErrorEmailToAdmin
} from '../utils/emailUtils';
import { addPriceToItems } from '../components/features/cart/utils';
import { MyPosSuccessData, useOrders } from '../hooks/useOrders';

export const CheckoutPage = () => {
  const [showSummary, setShowSummary] = useState<boolean>(true);
  const [showMyPos, setShowMyPos] = useState<boolean>(false);
  const [shipping, setShipping] = useState<ShippingData>({
    shippingCost: 0,
    minimumAmount: 0
  });
  const [myPosItems, setMyPosItems] = useState<MyPosProduct[]>([]);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [discountedTotalPrice, setDiscountedTotalPrice] = useState<number>(0);
  const [promoCode, setPromoCode] = useState<PromoCode | null>(null);
  const [enteredPromoCode, setEnteredPromoCode] = useState<string>('');
  const [isPromoCodeValid, setIsPromoCodeValid] = useState<boolean | null>(
    null
  );

  const {
    transactions: { areInTestMode: transactionsAreInTestMode }
  } = useAppSelector((state) => state.appConfig);
  const cartItems = useAppSelector((state) => state.cart);
  const { getProductById, isLoading: isFetchingProduct } = useProducts();
  const { getActiveDiscounts, isLoading: isFetchingDiscount } = useDiscounts();
  const { getShipping, isLoading: isFetchingShipping } = useShipping();
  const { sendOrderEmail, sendEmailToAdmin } = useMailgun();
  const { createOrder } = useOrders();
  const navigate = useCustomNavigate();

  const setShippingFromFirebase = async () => {
    const shippingData = await getShipping();
    setShipping(shippingData);
  };

  const createSummary = async () => {
    const activeDiscounts = await getActiveDiscounts();

    const mappedItems = await addPriceToItems(
      cartItems,
      getProductById,
      activeDiscounts
    );

    const myPosCartItems = mapCartItemsToMyPosProduct(mappedItems);
    const myPosTotalPrice = getTotalPrice(myPosCartItems);

    const isShippingFree = myPosTotalPrice >= shipping.minimumAmount;

    const myPosFinalItems = isShippingFree
      ? myPosCartItems
      : [
          ...myPosCartItems,
          {
            article: 'Доставка',
            quantity: 1,
            price: shipping.shippingCost,
            currency: 'BGN'
          }
        ];

    setMyPosItems(myPosFinalItems);
    setTotalPrice(myPosTotalPrice);
  };

  const createMyPos = async (orderShippingInfo: OrderShippingInfo) => {
    const amount = myPosItems.reduce((acc, item) => acc + item.price, 0);

    const sid = transactionsAreInTestMode
      ? '000000000000010'
      : process.env.REACT_APP_MYPOS_SID;
    const walletNumber = transactionsAreInTestMode
      ? '61938166610'
      : process.env.REACT_APP_MYPOS_WALLET_NUMBER;

    const paymentParams = {
      sid,
      ipcLanguage: 'bg',
      walletNumber,
      amount,
      currency: 'BGN',
      orderID: uuid4(),
      urlNotify: 'https://teniski-varna-api.vercel.app/myPos/notify',
      urlOk: window.location.href,
      urlCancel: window.location.href,
      keyIndex: 1,
      cartItems: myPosItems
    };

    const callbackParams = {
      isSandbox: transactionsAreInTestMode,
      // eslint-disable-next-line
      onSuccess: async (myPosData: MyPosSuccessData) => {
        const orderNumber = await createOrder(
          myPosData.OrderID,
          cartItems,
          {
            ...orderShippingInfo,
            shippingCost: shipping.shippingCost,
            minimumAmount: shipping.minimumAmount
          },
          promoCode
        );

        sendOrderEmailToCustomer(
          sendOrderEmail,
          myPosData,
          orderNumber,
          orderShippingInfo
        );
        sendOrderEmailToAdmin(
          sendOrderEmail,
          myPosData,
          orderNumber,
          orderShippingInfo,
          cartItems,
          promoCode,
          amount,
          shipping
        );

        setTimeout(() => {
          navigate('/thank-you', {
            state: {
              fromCheckout: true,
              orderNumber
            },
            replace: true
          });
        }, 1000);
      },

      onError: () => {
        sendOrderErrorEmailToAdmin(
          sendEmailToAdmin,
          orderShippingInfo,
          cartItems,
          promoCode,
          amount,
          shipping,
          true
        );
        navigate('/payment-error', {
          state: { fromCheckout: true },
          replace: true
        });
      }
    };

    MyPOSEmbedded.createPayment(
      'embeddedCheckout',
      paymentParams,
      callbackParams
    );
  };

  const onContinueToDelivery = () => {
    setShowSummary(false);
    scrollToTop();
  };

  const onContinueToMyPos = (orderShippingInfo: OrderShippingInfo) => {
    setShowMyPos(true);
    scrollToTop();

    // make sure the div is rendered before creating the MyPos payment
    setTimeout(() => {
      createMyPos(orderShippingInfo);
    }, 100);
  };

  const applyPromoCode = (promoCode: PromoCode | null) => {
    setPromoCode(promoCode);

    if (!promoCode) {
      return resetSummary();
    }

    const discountedTotalPrice = getDiscountedPrice(
      totalPrice,
      promoCode.percentage
    );

    setDiscountedTotalPrice(discountedTotalPrice);

    const discountedMyPosItems = myPosItems.map((item) => {
      if (item.article === 'Доставка') {
        return {
          ...item,
          price: shipping.shippingCost
        };
      }
      const discountedItemPrice = getDiscountedPrice(
        item.price,
        promoCode.percentage
      );

      return {
        ...item,
        price: discountedItemPrice
      };
    });
    setMyPosItems(discountedMyPosItems);
  };

  const resetSummary = () => {
    setIsPromoCodeValid(null);
    setDiscountedTotalPrice(0);
    createSummary();
  };

  const setupSummary = async () => {
    try {
      await setShippingFromFirebase();
      await createSummary();

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      toast.error(`💥 Нещо се обърка :( ${error.message}`);
    }
  };

  useLayoutEffect(() => {
    setupSummary();
    applyPromoCode(null);
  }, [cartItems]);

  const isLoading =
    isFetchingProduct || isFetchingDiscount || isFetchingShipping;

  return (
    <>
      {showSummary && !showMyPos && (
        <SummaryContainer
          cartItems={cartItems}
          totalPrice={totalPrice}
          discountedTotalPrice={discountedTotalPrice}
          shipping={shipping}
          enteredPromoCode={enteredPromoCode}
          setEnteredPromoCode={setEnteredPromoCode}
          isPromoCodeValid={isPromoCodeValid}
          setIsPromoCodeValid={setIsPromoCodeValid}
          onApplyPromoCode={(promoCode) => applyPromoCode(promoCode)}
          onContinue={onContinueToDelivery}
          isLoading={isLoading}
        />
      )}
      {!isLoading && !showMyPos && !showSummary && (
        <CheckoutContainer
          onGoBack={() => setShowSummary(true)}
          onContinueToMyPos={onContinueToMyPos}
        />
      )}
      {showMyPos && (
        <MyPosWrapper>
          <BackButton onClick={() => setShowMyPos(false)}>
            <icons.FaChevronLeft />
            <p>Обратно към Данни за Доставка</p>
          </BackButton>
          <div id="embeddedCheckout" />
        </MyPosWrapper>
      )}
    </>
  );
};

const BackButton = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: ${Color.DARK_GRAY};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const MyPosWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 20px;
`;

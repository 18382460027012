import { useState } from 'react';
import { EmailProps, EmailOrderSuccessProps } from '../utils/emailUtils';

const { REACT_APP_TENISKI_API_BASE_URL } = process.env;

interface MailGunResponse {
  status: number;
  id: string;
  message: string;
}

export const useMailgun = () => {
  const [response, setResponse] = useState<MailGunResponse>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const sendOrderEmail = async (props: EmailOrderSuccessProps) => {
    setIsLoading(true);

    const url = `${REACT_APP_TENISKI_API_BASE_URL}/mailgun/sendOrderEmail`;

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(props)
      });
      const data = await response.json();
      setResponse(data);
    } catch (e: unknown) {
      console.log('error', (e as Error).message);
      setResponse({ status: 500, id: '', message: (e as Error).message });
    } finally {
      setIsLoading(false);
    }
  };

  const sendEmailToAdmin = async (props: EmailProps) => {
    setIsLoading(true);

    const url = `${REACT_APP_TENISKI_API_BASE_URL}/mailgun/sendEmailToAdmin`;

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(props)
      });
      const data = await response.json();
      setResponse(data);
    } catch (e: unknown) {
      console.log('error', (e as Error).message);
      setResponse({ status: 500, id: '', message: (e as Error).message });
    } finally {
      setIsLoading(false);
    }
  };

  return { sendOrderEmail, sendEmailToAdmin, response, isLoading };
};

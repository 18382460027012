import React, { useState } from 'react';
import styled from 'styled-components';
import { Color } from '../assets/constants';
import { ActivityIndicator } from '../components/common/ActivityIndicator';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import { icons } from '../assets/icons';

export const AboutPage = () => {
  const [imageHasLoaded, setImageHasLoaded] = useState<boolean>(false);

  return (
    <Wrapper>
      <Row>
        <Title>За нас</Title>
        <icons.FaInfoCircle size={30} color={Color.DARK_GRAY} />
      </Row>
      <LargeSpacer />
      <ImageWrapper loaded={imageHasLoaded}>
        {!imageHasLoaded && (
          <ActivityIndicator size={100} color={Color.ACCENT} />
        )}
        <Zoom
          swipeToUnzoomThreshold={200}
          IconUnzoom={() => <icons.FaTimes size={22} />}
        >
          <StyledImage
            src={require('../assets/images/store.jpeg')}
            onLoad={() => setImageHasLoaded(true)}
            loaded={imageHasLoaded}
          />
        </Zoom>
      </ImageWrapper>
      <LargeSpacer />
      <BoldText>Тениски с щампа с най-високо качество</BoldText>
      <Spacer />
      <Text>
        Във Тениски Варна правим тениски, чаши, торбички и възглавници с
        висококачествен печат вече 2 години. Независимо дали търсиш тениска с
        щампа – снимка, някаква мисъл или просто нещо забавно, ние можем да ти
        помогнем да намериш точно това, което ти трябва. Произвеждаме качествени
        щамповани тениски само за минути! Разполагаме с най-новата технология в
        принта, а именно DTF - печат върху текстил, благодарение на който
        продуктите ни са ненадминати на пазара. Доказателство за това са
        доволните ни редовни клиенти.
      </Text>
      <LargeSpacer />
      <BoldText>Имаме богат опит</BoldText>
      <Spacer />
      <Text>
        Тениски Варна разполага с физически обект за щамповани мъжки, дамски и
        детски тениски, както и изработката на чаши с със снимка, възглавници с
        надпис и картинка, фото камъни. За да сме още по-близо до нашите
        клиенти, и най-вече до ТЕБ, където и да се намираш, вече имаме и онлайн
        магазин за тениски с щампи, чаши, възглавници и торбички. Никога не е
        било по-лесно да се сдобиеш с тениска от Тениски Варна.
      </Text>
      <LargeSpacer />
      <BoldText>Тениски с щампа за всеки повод</BoldText>
      <Spacer />
      <Text>
        За какъв повод ти трябва тениска? Всъщност, няма значение! Защото при
        нас разнообразието е толкова голямо, че със сигурност ще намериш това,
        което търсиш! Предлагаме голям асортимент – за теб и твоето ежедневие
        или за подарък. Разгледай, избери, поръчай и съвсем скоро ще е при теб.
        Имаме и нещо за хората с тяхна идея! Пиши ни какво искаш и виж как
        твоята идея се превръща в реалност.
      </Text>
      <LargeSpacer />
      <BoldText>Персонализирана тениска с щампа с твой дизайн</BoldText>
      <Spacer />
      <Text>
        Ние вярваме в света, в който ти имаш тоталната свобода да бъдеш себе си,
        без да те съдят. Да експериментираш. Да изразиш себе си. Да бъдеш смел и
        да приемаш живота като необикновено приключение. Затова правим всичко
        възможно да откриеш най-доброто за теб – без значение откъде си или
        какъв е стилът ти. Ние съществуваме, за да ти вдъхнем увереност да бъдеш
        този, който искаш.
      </Text>
      <LargeSpacer />
    </Wrapper>
  );
};

const LargeSpacer = styled.div`
  margin: 24px;
`;

const Spacer = styled.div`
  margin: 8px;
`;

const BoldText = styled.p`
  font-weight: bold;
`;

const Text = styled.p``;

const StyledImage = styled.img<{ loaded: boolean }>`
  width: 100%;
  transform: translateY(-10%);
  ${({ loaded }) => !loaded && 'display: none;'}
`;

const ImageWrapper = styled.div<{ loaded: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  aspect-ratio: 16 / 9;
  border-radius: 2rem;
  overflow: hidden;
  ${({ loaded }) => !loaded && 'background-color: #f0f0f0;'}
`;

const Title = styled.h1`
  font-size: 1.5rem;
  color: ${Color.DARK_GRAY};
  font-weight: bold;
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 1.5rem;
  width: 100%;
`;

const Wrapper = styled.div`
  padding: 0 1.5rem 1.5rem 1.5rem;
  color: ${Color.DARK_GRAY};
`;

import React, { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged, User } from 'firebase/auth';
import { AdminPanelAuth } from '../containers/adminPanel/AdminPanelAuth';
import { OrdersContainer } from '../containers/adminPanel/OrdersContainer';

export const OrdersPage = () => {
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => setUser(user));

    return () => unsubscribe();
  }, []);

  return user !== null ? <OrdersContainer /> : <AdminPanelAuth />;
};

import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Button, ButtonSize, ButtonType } from '../../common/Button';
import { Label } from '../../../hooks/useLabels';
import { icons } from '../../../assets/icons';
import { Color } from '../../../assets/constants';

const SCROLL_DISTANCE = 350;

enum ArrowButtonDirection {
  LEFT = 'left',
  RIGHT = 'right'
}

interface Props {
  labels: Label[];
  selected?: Label;
  onSelectLabel: (label: Label) => void;
}

export const LabelSlider = ({ labels, selected, onSelectLabel }: Props) => {
  const [isStartVisible, setIsStartVisible] = useState(true);
  const [isEndVisible, setIsEndVisible] = useState(false);
  const sliderRef = useRef<HTMLDivElement | null>(null);

  const updateVisibility = () => {
    if (sliderRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = sliderRef.current;

      setIsStartVisible(scrollLeft === 0);
      setIsEndVisible(
        Math.round(scrollLeft) + Math.round(clientWidth) >=
          Math.round(scrollWidth) - 1
      );
    }
  };

  useEffect(() => {
    const slider = sliderRef.current;

    const resizeObserver = new ResizeObserver(() => {
      updateVisibility();
    });

    if (slider) {
      resizeObserver.observe(slider);
      slider.addEventListener('scroll', updateVisibility);
    }

    updateVisibility();

    return () => {
      if (slider) {
        resizeObserver.unobserve(slider);
        slider.removeEventListener('scroll', updateVisibility);
      }
    };
  }, []);

  const getButtonType = (label: Label): ButtonType => {
    if (!selected) {
      return ButtonType.UNSELECTED;
    }

    return label.id === selected.id
      ? ButtonType.PRIMARY
      : ButtonType.UNSELECTED;
  };

  const scrollLeft = () => {
    sliderRef.current?.scrollBy({
      left: -SCROLL_DISTANCE,
      behavior: 'smooth'
    });
  };

  const scrollRight = () => {
    sliderRef.current?.scrollBy({
      left: SCROLL_DISTANCE,
      behavior: 'smooth'
    });
  };

  return (
    <Wrapper>
      {!isStartVisible && (
        <ArrowButtonWrapper direction={ArrowButtonDirection.LEFT}>
          <ArrowButton onClick={scrollLeft}>
            <icons.FaChevronLeft color={Color.GRAY} />
          </ArrowButton>
        </ArrowButtonWrapper>
      )}

      <Scroll ref={sliderRef}>
        {labels.map((label, index) => {
          return (
            <ButtonContainer key={`label-${index}`}>
              <Button
                label={label.name}
                type={getButtonType(label)}
                onClick={() => onSelectLabel(label)}
                size={ButtonSize.MEDIUM}
              />
            </ButtonContainer>
          );
        })}
      </Scroll>

      {!isEndVisible && (
        <ArrowButtonWrapper direction={ArrowButtonDirection.RIGHT}>
          <ArrowButton onClick={scrollRight}>
            <icons.FaChevronRight color={Color.GRAY} />
          </ArrowButton>
        </ArrowButtonWrapper>
      )}
    </Wrapper>
  );
};

const ArrowButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Color.LIGHT_GRAY};
  border-radius: 50%;
  height: 45px;
  width: 45px;
  padding: 10px;
  cursor: pointer;

  &:hover {
    filter: brightness(0.9);
  }
  &:active {
    transform: scale(0.9);
  }
`;

const ArrowButtonWrapper = styled.div<{ direction: ArrowButtonDirection }>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ direction }) =>
    direction === ArrowButtonDirection.RIGHT
      ? `linear-gradient(90deg, transparent 0%, ${Color.WHITE} 25%);`
      : `linear-gradient(90deg, ${Color.WHITE} 75%, transparent 100%);`};
  height: 55px;
  width: 70px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: ${({ direction }) =>
    direction === ArrowButtonDirection.LEFT ? 0 : 10}px;
  margin-right: ${({ direction }) =>
    direction === ArrowButtonDirection.RIGHT ? 0 : 10}px;
  padding: ${({ direction }) =>
    direction === ArrowButtonDirection.LEFT ? '0 25px 0 0' : '0 0 0 25px'};
  z-index: 100;

  top: 0;
  ${({ direction }) =>
    direction === ArrowButtonDirection.LEFT ? `left: 0;` : `right: 0;`}

  @media (max-width: 450px) {
    display: none;
  }
`;

const Scroll = styled.div`
  display: flex;
  flex-wrap: none;
  overflow-x: scroll;
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  @media (min-width: 768px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

const ButtonContainer = styled.div`
  margin: 10px 10px 10px 0;
`;

const Wrapper = styled.div`
  position: relative;
`;

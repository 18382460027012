import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Color } from '../assets/constants';
import { ActivityIndicator } from '../components/common/ActivityIndicator';
import { BannerSlider } from '../components/features/home/BannerSlider';
import { LabelSlider } from '../components/features/home/LabelSlider';
import { ProductList } from '../components/features/home/ProductList';
import { Product } from '../domain/models/ProductDTO';
import { Label, useLabels } from '../hooks/useLabels';
import { ScrollToTopButton } from '../components/features/home/ScrollToTopButton';
import { useElementOnScreen } from '../hooks/useElementOnScreen';
import { useAppDispatch, useAppSelector } from '../hooks/useRedux';
import { labelsActions } from '../store/labelsSlice';

interface Props {
  products: Product[];
  onLoadMore: () => void;
  allProductsHaveBeenFetched: boolean;
  searchTerm: boolean;
  isLoading: boolean;
}

export const HomeContainer = ({
  products,
  onLoadMore,
  allProductsHaveBeenFetched,
  searchTerm,
  isLoading
}: Props) => {
  const [labels, setLabels] = useState<Label[]>([]);
  const { getLabels } = useLabels();
  const { containerRef: topRef, isVisible: isHeaderVisible } =
    useElementOnScreen();
  const { containerRef: bottomRef, isVisible: isFooterVisible } =
    useElementOnScreen();
  const { selectedLabel } = useAppSelector((state) => state.labels);
  const dispatch = useAppDispatch();

  const setLabelsFromFirebase = async () => {
    const fetchedLabels = await getLabels();
    setLabels(fetchedLabels);
  };

  useEffect(() => {
    setLabelsFromFirebase();
  }, []);

  useEffect(() => {
    if (!searchTerm) {
      dispatch(labelsActions.setSelectedLabel(undefined));
    }
  }, [searchTerm]);

  const onSelectLabel = (label: Label) => {
    if (label.id === selectedLabel?.id) {
      dispatch(labelsActions.setSelectedLabel(undefined));
    } else {
      dispatch(labelsActions.setSelectedLabel(label));
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      <div ref={topRef}></div>
      <BannerSlider />
      <LabelSlider
        labels={labels}
        selected={selectedLabel}
        onSelectLabel={(label: Label) => onSelectLabel(label)}
      />
      <ProductListContainer>
        {isLoading && <ActivityIndicator size={75} color={Color.ACCENT} />}
        {!isLoading && products.length > 0 && (
          <ProductList
            products={products}
            onLoadMore={onLoadMore}
            allProductsHaveBeenFetched={allProductsHaveBeenFetched}
            selectedLabel={selectedLabel}
          />
        )}
      </ProductListContainer>
      <div ref={bottomRef}></div>
      <ScrollToTopButtonWrapper>
        <ScrollToTopButton
          onScrollToTop={scrollToTop}
          showButton={!isHeaderVisible}
          isFooterVisible={isFooterVisible}
        />
      </ScrollToTopButtonWrapper>
    </>
  );
};

const ScrollToTopButtonWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
`;

export const ProductListContainer = styled.div`
  padding: 10px 1.5rem 5rem 1.5rem;

  @media (min-width: 768px) {
    padding: 10px 0 5rem 0;
  }
`;

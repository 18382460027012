import { TShirtColor } from '../../containers/adminPanel/utils';
import { TShirtSize, TShirtType } from '../models/ProductDTO';
import { Product } from '../models/ProductDTO';

export interface CartProduct {
  id: string;
  title: string;
  description: string;
  image: string;
  price: number;
  color: TShirtColor;
  size: TShirtSize;
  type: TShirtType;
  customizationNotes?: string;
}

export interface MyPosProduct {
  article: string;
  quantity: number;
  price: number;
  currency: string;
}

export const mapProductToCartProduct = (
  product: Product,
  color: TShirtColor,
  image: string,
  selectedSize: TShirtSize,
  selectedType: TShirtType,
  customizationNotes?: string,
  discountedPrice?: number
): CartProduct => ({
  id: product.id,
  title: product.title,
  description: product.description,
  image,
  price: Number(discountedPrice ?? product.price),
  color,
  size: selectedSize,
  type: selectedType,
  customizationNotes
});

export const mapCartItemsToMyPosProduct = (
  cartItems: CartProduct[]
): MyPosProduct[] =>
  cartItems.map(
    (item: CartProduct): MyPosProduct => ({
      article: 'Тениска с щампа',
      quantity: 1,
      price: item.price,
      currency: 'BGN'
    })
  );

import React from 'react';
import styled from 'styled-components';

export enum SwitchSize {
  SMALL = 'small',
  LARGE = 'large'
}

interface ButtonSize {
  width: string;
  offset: string;
  activeWidth: string;
}

interface LabelSize {
  width: string;
  height: string;
}

interface Props {
  isOn: boolean;
  handleToggle: () => void;
  id: string;
  size?: SwitchSize;
}

const Switch = ({ isOn, handleToggle, id, size }: Props) => {
  const getButtonSize = (size?: SwitchSize): ButtonSize => {
    switch (size) {
      case SwitchSize.SMALL:
        return { width: '25px', offset: '0.5px', activeWidth: '30px' };
      case SwitchSize.LARGE:
        return { width: '45px', offset: '2px', activeWidth: '60px' };
      default:
        return { width: '45px', offset: '2px', activeWidth: '60px' };
    }
  };

  const getLabelSize = (size?: SwitchSize): LabelSize => {
    switch (size) {
      case SwitchSize.SMALL:
        return { width: '50px', height: '26px' };
      case SwitchSize.LARGE:
        return { width: '100px', height: '50px' };
      default:
        return { width: '100px', height: '50px' };
    }
  };

  return (
    <>
      <SwitchCheckbox
        checked={isOn}
        onChange={handleToggle}
        id={id}
        type="checkbox"
      />
      <SwitchLabel htmlFor={id} isOn={isOn} size={getLabelSize(size)}>
        <SwitchButton size={getButtonSize(size)} />
      </SwitchLabel>
    </>
  );
};

export default Switch;

const SwitchCheckbox = styled.input`
  height: 0;
  width: 0;
  visibility: hidden;
`;

const SwitchLabel = styled.label<{ isOn: boolean; size: LabelSize }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: ${({ size }) => size.width};
  height: ${({ size }) => size.height};
  background-color: ${({ isOn }) => (isOn ? '#06D6A0' : 'grey')};
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
`;

const SwitchButton = styled.span<{ size: ButtonSize }>`
  content: '';
  position: absolute;
  top: ${({ size }) => size.offset};
  left: ${({ size }) => size.offset};
  width: ${({ size }) => size.width};
  height: ${({ size }) => size.width};
  border-radius: ${({ size }) => size.width};
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);

  ${SwitchCheckbox}:checked + ${SwitchLabel} & {
    left: calc(100%);
    transform: translateX(-100%);
  }

  ${SwitchLabel}:active & {
    width: ${({ size }) => size.activeWidth};
  }
`;

import React from 'react';
import styled from 'styled-components';
import { Color } from '../../assets/constants';
import { icons } from '../../assets/icons';
import { useCustomNavigate } from '../../hooks/useCustomNavigate';

interface Props {
  text?: string;
  textColor?: Color;
  backButton?: boolean;
}

export const EmptyProductList = ({
  text = 'Не успяхме да открием нищо тук…',
  textColor = Color.GRAY,
  backButton
}: Props) => {
  const navigate = useCustomNavigate();

  return (
    <EmptyContainer>
      <StyledImage
        src={require('../../assets/images/broken-heart-icon-upscaled.png')}
      />
      <Text color={textColor}>{text}</Text>
      {backButton && (
        <BackButton onClick={() => navigate('/', { replace: true })}>
          <icons.FaChevronLeft />
          <p>Обратно към Тениски Варна</p>
        </BackButton>
      )}
    </EmptyContainer>
  );
};

const StyledImage = styled.img`
  width: 100px;
`;

const BackButton = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: ${Color.GRAY};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const Text = styled.p<{ color?: Color }>`
  font-size: 1.5rem;
  color: ${({ color }) => color};
`;

const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 10px;
  height: 100%;
  margin-top: 4rem;
`;

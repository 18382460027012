import {
  TShirtSize,
  TShirtSizes,
  TShirtType
} from '../../domain/models/ProductDTO';
import { TShirtColor } from '../adminPanel/utils';

export const getAvailableSizes = (
  sizes: TShirtSizes,
  selectedColor: TShirtColor | null,
  type: TShirtType
) => {
  switch (type) {
    case TShirtType.OVERSIZED:
      return sizes.oversized;
    case TShirtType.WOMEN:
      return sizes.women;
    case TShirtType.MEN:
      return selectedColor === TShirtColor.WHITE ||
        selectedColor === TShirtColor.BLACK
        ? sizes.men
        : sizes.men.filter((size) => size !== TShirtSize.XXXL);
    case TShirtType.KIDS:
      return selectedColor === TShirtColor.WHITE ||
        selectedColor === TShirtColor.BLACK
        ? sizes.kids
        : sizes.kids.filter((size) => size !== TShirtSize.K98);
  }
};

import React from 'react';
import styled from 'styled-components';

export const Map = () => (
  <Wrapper>
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2907.120447045196!2d27.872369176491706!3d43.22793427112553!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40a4556a9f13df2b%3A0x62fdf3b131c47559!2z0KLQtdC90LjRgdC60Lgg0JLQsNGA0L3QsA!5e0!3m2!1sen!2sbg!4v1733239488123!5m2!1sen!2sbg"
      width="100%"
      height="100%"
      style={{ borderRadius: 10 }}
      allowFullScreen={true}
      loading="lazy"
    ></iframe>
  </Wrapper>
);

const Wrapper = styled.div`
  width: 100%;
  height: 400px;
`;

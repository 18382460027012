import { TShirtSize, TShirtType } from '../domain/models/ProductDTO';
import {
  CartProduct,
  mapProductToCartProduct
} from '../domain/mappers/cartProductMapper';
import { createSlice } from '@reduxjs/toolkit';
import { getLocalCartItems, cartItemExists } from './utils';

export type LocalItem = {
  id: string;
  color: string;
  image: string;
  size: TShirtSize;
  type: TShirtType;
  customizationNotes?: string;
};

export type InitialState = CartProduct[];

const initialState: InitialState = [];

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    fillCart: (state, { payload }) => {
      Object.assign(state, payload);
    },
    addToCart: (state, { payload }) => {
      const product = mapProductToCartProduct(
        payload.product,
        payload.selectedColor,
        payload.image,
        payload.selectedSize,
        payload.selectedType,
        payload.customizationNotes
      );

      const localItems = getLocalCartItems();

      const localItem: LocalItem = {
        id: product.id,
        color: product.color,
        image: product.image,
        size: product.size,
        type: product.type,
        customizationNotes: product.customizationNotes
      };

      state.push(product);
      localItems.push(localItem);
      localStorage.setItem('cartItems', JSON.stringify(localItems));
    },
    removeFromCart: (state, { payload }) => {
      const localItems = getLocalCartItems();
      const stateIndex = state.findIndex((item) =>
        cartItemExists(item, payload.product)
      );

      state.splice(stateIndex, 1);
      const localItemIndex = localItems.findIndex((item) =>
        cartItemExists(item, payload.product)
      );
      localItems.splice(localItemIndex, 1);
      localStorage.setItem('cartItems', JSON.stringify(localItems));
    },
    clearCart: (state) => {
      state.splice(0, state.length);
      localStorage.setItem('cartItems', JSON.stringify([]));
    }
  }
});

export const cartActions = cartSlice.actions;

import { CartProduct } from '../domain/mappers/cartProductMapper';
import { Product } from '../domain/models/ProductDTO';
import { LocalItem } from './cartSlice';

export const getLocalCartItems = (): LocalItem[] =>
  JSON.parse(localStorage.getItem('cartItems') ?? '[]');

export const getLocalFavorites = (): Product[] =>
  JSON.parse(localStorage.getItem('favorites') ?? '[]');

export const cartItemExists = (
  item: CartProduct | LocalItem,
  payload: CartProduct
): boolean =>
  item.id === payload.id &&
  item.size === payload.size &&
  item.color === payload.color;

import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Color } from '../../../assets/constants';
import { icons } from '../../../assets/icons';
import { Label, useLabels } from '../../../hooks/useLabels';
import { ActivityIndicator } from '../../common/ActivityIndicator';
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import { labelsActions } from '../../../store/labelsSlice';
import { useLocation } from 'react-router';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';

interface Props {
  label: string;
  isAccordionOpen: boolean;
  setIsAccordionOpen: (isOpen: boolean) => void;
  isSmallScreen?: boolean;
  onCloseMenu?: () => void;
}

export const LabelsAccordion = ({
  label,
  isAccordionOpen,
  setIsAccordionOpen,
  isSmallScreen,
  onCloseMenu
}: Props) => {
  const [labels, setLabels] = useState<Label[]>([]);
  const [contentHeight, setContentHeight] = useState<number>(0);
  const contentRef = useRef<HTMLDivElement>(null);

  const { getLabels, isFetchingLabels } = useLabels();
  const { selectedLabel } = useAppSelector((state) => state.labels);
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const navigate = useCustomNavigate();

  const setLabelsFromFirebase = async () => {
    const labels = await getLabels();
    setLabels(labels);
  };

  useEffect(() => {
    setLabelsFromFirebase();
  }, []);

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  }, [labels, isFetchingLabels]);

  const toggleAccordion = () => setIsAccordionOpen(!isAccordionOpen);

  const handleSelectLabel = (label: Label) => {
    if (selectedLabel?.id === label.id) {
      dispatch(labelsActions.setSelectedLabel(undefined));
    } else {
      dispatch(labelsActions.setSelectedLabel(label));
    }

    if (pathname !== '/') {
      navigate('/');
    }

    onCloseMenu && onCloseMenu();
  };

  return (
    <div>
      <AccordionButton onClick={toggleAccordion}>
        <ChevronIcon isOpen={isAccordionOpen}>
          <icons.FaChevronCircleDown color={Color.DARK_GRAY} size={25} />
        </ChevronIcon>
        {label}
      </AccordionButton>
      <LabelsContainer
        ref={contentRef}
        isOpen={isAccordionOpen}
        height={contentHeight}
        isSmallScreen={isSmallScreen}
      >
        {isFetchingLabels ? (
          <ActivityIndicatorWrapper>
            <ActivityIndicator color={Color.ACCENT} size={30} />
          </ActivityIndicatorWrapper>
        ) : (
          labels.map((label) => (
            <AccordionButton
              onClick={() => handleSelectLabel(label)}
              isSelected={selectedLabel?.id === label.id}
              smallFont
              key={label.id}
            >
              {label.name}
            </AccordionButton>
          ))
        )}
      </LabelsContainer>
    </div>
  );
};

const ActivityIndicatorWrapper = styled.div`
  margin-left: 2rem;
`;

const ChevronIcon = styled.div<{ isOpen: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
  transform: rotate(${({ isOpen }) => (isOpen ? '-180deg' : '0deg')});
`;

const LabelsContainer = styled.div<{
  isOpen: boolean;
  height: number;
  isSmallScreen?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-left: 2rem;
  transition: height 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.2s ease-in-out,
    transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  height: ${({ isOpen, height }) => (isOpen ? height : 0)}px;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};

  ${({ isSmallScreen }) =>
    !isSmallScreen &&
    `
    @media (min-width: 1024px) {
      max-height: 400px;
      overflow-y: auto;
      scrollbar-width: thin;
      scrollbar-color: ${Color.LIGHT_GRAY} transparent;
    }
  
    @media (max-height: 950px) {
      max-height: 300px;
    }
  
    @media (max-height: 850px) {
      max-height: 200px;
    }
  `}
`;

const AccordionButton = styled.div<{
  smallFont?: boolean;
  isSelected?: boolean;
}>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  font-size: ${({ smallFont }) => (smallFont ? 1 : 1.5)}rem;
  color: ${Color.DARK_GRAY};
  cursor: pointer;
  text-decoration: none;
  border-radius: 10px;
  width: 100%;

  &:hover {
    ${({ isSelected }) =>
      !isSelected
        ? `background-color: ${Color.LIGHT_GRAY};`
        : `filter: brightness(0.9);`}
  }

  background-color: ${({ isSelected }) =>
    isSelected ? Color.ACCENT : 'transparent'};
`;

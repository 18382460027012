import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Product } from '../../../domain/models/ProductDTO';
import { ProductCard } from './ProductCard';
import { Label } from '../../../hooks/useLabels';
import { getDiscountForProduct } from '../../../containers/adminPanel/utils';
import { Discount, useDiscounts } from '../../../hooks/useDiscounts';
import InfiniteScroll from 'react-infinite-scroller';
import { ActivityIndicator } from '../../common/ActivityIndicator';
import { Color } from '../../../assets/constants';
import { EmptyProductList } from '../../common/EmptyList';

interface Props {
  products: Product[];
  cols?: number;
  onLoadMore?: () => void;
  allProductsHaveBeenFetched?: boolean;
  selectedLabel?: Label;
  onSelectProductToEdit?: (productId: string) => void;
}

export const ProductList = ({
  products,
  cols,
  onLoadMore,
  allProductsHaveBeenFetched,
  selectedLabel,
  onSelectProductToEdit
}: Props) => {
  const [activeDiscounts, setActiveDiscounts] = useState<Discount[]>([]);
  const { getActiveDiscounts } = useDiscounts();

  const setDiscountsFromFirebase = async () => {
    const discounts = await getActiveDiscounts();
    setActiveDiscounts(discounts);
  };

  useEffect(() => {
    setDiscountsFromFirebase();
  }, []);

  const noProductsForSelectedLabel =
    selectedLabel &&
    products.every(
      (product) => !product.labels.includes(selectedLabel?.id ?? '')
    );

  return (
    <>
      {noProductsForSelectedLabel ? (
        <EmptyProductList />
      ) : (
        <StyledInfiniteScroll
          pageStart={0}
          loadMore={onLoadMore ? () => onLoadMore() : () => ({})}
          hasMore={!allProductsHaveBeenFetched}
          loader={
            <Loader key={'loader'}>
              <Text>Зареждане на още продукти...</Text>
              <ActivityIndicator size={75} color={Color.ACCENT} />
            </Loader>
          }
          threshold={0}
          cols={cols}
        >
          {products.map((product) => {
            if (selectedLabel && !product.labels.includes(selectedLabel.id)) {
              return null;
            }

            const discount = getDiscountForProduct(product, activeDiscounts);

            return (
              <ProductCard
                product={product}
                discount={discount}
                onSelectProductToEdit={onSelectProductToEdit}
                key={product.id}
              />
            );
          })}
        </StyledInfiniteScroll>
      )}
    </>
  );
};

const Text = styled.p`
  font-size: 1rem;
  color: ${Color.GRAY};
  text-align: center;
`;

const Loader = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1 / -1;
  padding: 1rem;
`;

const StyledInfiniteScroll = styled(InfiniteScroll)<{ cols?: number }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.8rem;

  @media (min-width: 768px) {
    grid-template-columns: repeat(${({ cols }) => cols ?? 3}, 1fr);
    grid-gap: 1rem;
  }
`;

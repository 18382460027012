import React, { useEffect, useState } from 'react';
import { Order, useOrders } from '../../hooks/useOrders';
import styled from 'styled-components';
import { Color } from '../../assets/constants';
import { OrdersTable } from '../../components/features/tables/OrdersTable';
import { ReactComponent as Logo } from '../../assets/images/logo-horizontal.svg';
import { icons } from '../../assets/icons';
import { useCustomNavigate } from '../../hooks/useCustomNavigate';

export const OrdersContainer = () => {
  const [orders, setOrders] = useState<Order[]>([]);
  const { getAllOrders, isLoading } = useOrders();
  const navigate = useCustomNavigate();

  const fetchOrders = async () => {
    const orders = await getAllOrders();
    setOrders(orders);
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  return (
    <Wrapper>
      <Header>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
      </Header>
      <TableWrapper>
        <BackButton onClick={() => navigate('/admin-panel')}>
          <icons.FaChevronLeft />
          <p>Back to Admin Panel</p>
        </BackButton>
        <OrdersTable orders={orders} isLoading={isLoading} />
      </TableWrapper>
    </Wrapper>
  );
};

const BackButton = styled.p`
  display: flex;
  align-items: center;
  gap: 10px;
  padding-bottom: 10px;
  color: ${Color.DARK_GRAY};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const LogoWrapper = styled.div`
  width: 300px;
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 24px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  background: ${Color.GRAY};
  min-height: 100svh;
  width: 100svw;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

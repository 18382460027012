import { useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase/firebaseConfig';
import { toast } from 'react-toastify';
import { useAppDispatch } from './useRedux';
import { configActions } from '../store/configSlice';

interface TransactionsConfig {
  areInTestMode: boolean;
  areDisabled: boolean;
}

export interface AppConfig {
  isInMaintenanceMode: boolean;
  transactions: TransactionsConfig;
}

export const useAppConfig = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const setConfig = async () => {
    setIsLoading(true);

    try {
      const maintenanceRef = doc(db, 'config', 'maintenance');
      const transactionsConfigRef = doc(db, 'config', 'transactions');

      const [maintenanceConfig, transactionsConfig] = await Promise.all([
        await getDoc(maintenanceRef),
        await getDoc(transactionsConfigRef)
      ]);

      if (!maintenanceConfig.exists()) {
        throw new Error('Maintenance config not found');
      }

      if (!transactionsConfig.exists()) {
        throw new Error('Transaction config not found');
      }

      const config = {
        isInMaintenanceMode: Boolean(maintenanceConfig.data().isOn),
        transactions: {
          areInTestMode: Boolean(transactionsConfig.data().testMode),
          areDisabled: Boolean(transactionsConfig.data().disabled)
        }
      };

      dispatch(configActions.setConfig(config));
    } catch (e: unknown) {
      toast.error(`💥 ${(e as Error).message}`);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setConfig();
  }, []);

  return {
    isLoading
  };
};

import React, { useState } from 'react';
import styled from 'styled-components';
import { useTheme } from '@table-library/react-table-library/theme';
import {
  DEFAULT_OPTIONS,
  getTheme
} from '@table-library/react-table-library/chakra-ui';
import { useRowSelect } from '@table-library/react-table-library/select';
import {
  HeaderCellSort,
  useSort
} from '@table-library/react-table-library/sort';
import { MiddlewareFunction } from '@table-library/react-table-library/types/common';
import { Order } from '../../../hooks/useOrders';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { ActivityIndicator } from '../../common/ActivityIndicator';
import { Color } from '../../../assets/constants';
import { icons } from '../../../assets/icons';
import { Input } from '../../common/Input';
import {
  Body,
  Cell,
  Header,
  HeaderCell,
  HeaderRow,
  Row,
  Table
} from '@table-library/react-table-library';

interface Props {
  orders: Order[];
  isLoading: boolean;
}

export const OrdersTable = ({ orders, isLoading }: Props) => {
  const [search, setSearch] = useState('');
  const chakraTheme = getTheme(DEFAULT_OPTIONS);
  const customTheme = useTheme({
    Table: `
      border-radius: 10px;
      --data-table-library_grid-template-columns: 2fr 2fr 1fr 1fr 1fr;
    `,
    Row: `
      &.status-pending {
        background-color: #edd377;
      }
      
      &.status-sent {
        background-color: #adebb3;
      }

      &.status-cancelled {
        background-color: #ffa896;
      }

      &.test-order {
        background: #fdf4e3;
      }
    `
  });
  const theme = useTheme([chakraTheme, customTheme]);
  const navigate = useCustomNavigate();

  const getOrderStatus = ({ status, myPosData }: Order) => {
    if (status === 'PENDING') {
      return 'status-pending';
    }

    if (status === 'SENT') {
      return 'status-sent';
    }

    if (status === 'CANCELLED') {
      return 'status-cancelled';
    }

    if (
      myPosData.SID === '000000000000010' ||
      myPosData.BillingDescriptor.includes('TESTSTORE')
    ) {
      return 'test-order';
    }

    return '';
  };

  const data = {
    nodes: orders.filter((item) => {
      return (
        item.orderNumber.includes(search) ||
        `${item.shippingInfo.firstName} ${item.shippingInfo.lastName}`
          .toLowerCase()
          .includes(search.toLowerCase())
      );
    })
  };

  const onRowClick: MiddlewareFunction = (action) => {
    navigate(`/admin-panel/orders/${action.payload.id}`);
  };

  const select = useRowSelect(data, {
    onChange: onRowClick
  });

  const sort = useSort(
    data,
    {
      state: {
        sortKey: 'DATE'
      }
    },
    {
      sortIcon: {
        iconDefault: <icons.FaSort size={14} />,
        iconUp: <icons.FaSortUp size={14} />,
        iconDown: <icons.FaSortDown size={14} />
      },
      sortFns: {
        DATE: (array) =>
          array.sort(
            (a, b) =>
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          )
      }
    }
  );

  return (
    <TableWrapper>
      {isLoading ? (
        <ActivityIndicatorWrapper>
          <ActivityIndicator size={100} color={Color.ACCENT} />
        </ActivityIndicatorWrapper>
      ) : (
        <>
          <InputWrapper>
            <Input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder={'Order number / customer name'}
            />
          </InputWrapper>

          <Table
            data={data}
            sort={sort}
            theme={theme}
            select={select}
            layout={{ custom: true, horizontalScroll: true }}
          >
            {/* eslint-disable-next-line */}
            {(tableList: any) => (
              <>
                <Header>
                  <HeaderRow>
                    <HeaderCellSort sortKey="DATE">Date</HeaderCellSort>
                    <HeaderCell>Customer</HeaderCell>
                    <HeaderCell>Amount</HeaderCell>
                    <HeaderCell>Order number</HeaderCell>
                    <HeaderCell>Status</HeaderCell>
                  </HeaderRow>
                </Header>

                <Body>
                  {tableList.map((order: Order) => (
                    <Row
                      item={order}
                      className={getOrderStatus(order)}
                      key={order.id}
                    >
                      <Cell>{order.createdAt}</Cell>
                      <Cell>{`${order.shippingInfo.firstName} ${order.shippingInfo.lastName}`}</Cell>
                      <Cell>{`${Number(order.myPosData.Amount).toFixed(2)} ${
                        order.myPosData.Currency
                      }`}</Cell>
                      <Cell>{order.orderNumber}</Cell>
                      <Cell>{order.status}</Cell>
                    </Row>
                  ))}
                </Body>
              </>
            )}
          </Table>
        </>
      )}
    </TableWrapper>
  );
};

const ActivityIndicatorWrapper = styled.div`
  background-color: ${Color.WHITE};
  border-radius: 10px;
  min-width: 300px;
`;

const InputWrapper = styled.div`
  padding: 1rem;
  max-width: 400px;
`;

const TableWrapper = styled.div`
  width: 100%;
  background-color: ${Color.WHITE};
  border-radius: 10px;
`;

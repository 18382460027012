import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Color } from '../../../assets/constants';
import { Link } from 'react-router-dom';
import { icons } from '../../../assets/icons';
import { useAppSelector } from '../../../hooks/useRedux';
import { Cart } from '../cart/Cart';
import { cartTickPopAnimation } from '../../../utils/animations';
import { LabelsAccordion } from './LabelsAccordion';
import { isDev } from '../../../utils/environment';

const pages = [
  {
    name: 'Начало',
    icon: icons.FaHome,
    path: '/'
  },
  {
    name: 'Категории',
    icon: icons.FaChevronCircleDown,
    path: null
  },
  {
    name: 'Любими',
    icon: icons.FaHeart,
    path: '/favorites'
  },
  {
    name: 'Кошница',
    icon: icons.FaShoppingBag,
    path: null
  },
  {
    name: 'За нас',
    icon: icons.FaInfoCircle,
    path: '/about'
  },
  {
    name: 'Контакти',
    icon: icons.FaPhone,
    path: '/contact'
  },
  {
    name: 'ЧЗВ',
    icon: icons.FaQuestionCircle,
    path: '/faq'
  }
];

interface MenuLinkProps {
  currentPage: string;
  isSmallScreen?: boolean;
  onCloseMenu?: () => void;
}

export const MenuLinks = ({
  currentPage,
  isSmallScreen,
  onCloseMenu
}: MenuLinkProps) => {
  const [showCart, setShowCart] = useState<boolean>(false);
  const [cartItemsPop, setCartItemsPop] = useState<boolean>(false);
  const [favoriteItemsPop, setFavoriteItemsPop] = useState<boolean>(false);
  const [isAccordionOpen, setIsAccordionOpen] = useState<boolean>(false);
  const cartItems = useAppSelector((state) => state.cart);
  const favorites = useAppSelector((state) => state.favorites);
  const {
    transactions: { areDisabled }
  } = useAppSelector((state) => state.appConfig);

  const areTransactionsDisabled = areDisabled && !isDev();

  const cartItemsQuantity = cartItems.length;
  const favoriteItemsQuantity = favorites.length;

  useEffect(() => {
    if (cartItemsQuantity > 0) {
      setCartItemsPop(true);
      setTimeout(() => setCartItemsPop(false), 400);
    }
  }, [cartItemsQuantity]);

  useEffect(() => {
    if (favoriteItemsQuantity > 0) {
      setFavoriteItemsPop(true);
      setTimeout(() => setFavoriteItemsPop(false), 400);
    }
  }, [favoriteItemsQuantity]);

  const handleCloseMenuAndCart = () => {
    setShowCart(false);
    onCloseMenu && onCloseMenu();
  };

  const handleCloseMenuAndAccordion = () => {
    setIsAccordionOpen(false);
    onCloseMenu && onCloseMenu();
  };

  return (
    <NavigationContainer>
      <Cart
        cartItems={cartItems}
        showCart={showCart}
        onCloseCart={handleCloseMenuAndCart}
      />
      {pages.map((page) => {
        if (page.name === 'Категории') {
          return (
            <LabelsAccordion
              label={'Категории'}
              isAccordionOpen={isAccordionOpen}
              setIsAccordionOpen={setIsAccordionOpen}
              isSmallScreen={isSmallScreen}
              onCloseMenu={onCloseMenu}
              key={page.name}
            />
          );
        }
        if (page.path === null) {
          return (
            <CartButton
              disabled={areTransactionsDisabled}
              onClick={() => !areTransactionsDisabled && setShowCart(true)}
              key={page.name}
            >
              <ItemTick color={Color.ACCENT} pop={cartItemsPop}>
                {cartItemsQuantity}
              </ItemTick>
              <page.icon color={Color.DARK_GRAY} size={25} />
              {page.name}
            </CartButton>
          );
        }
        return (
          <StyledLink
            $currentPage={currentPage === page.path}
            to={page.path}
            onClick={handleCloseMenuAndAccordion}
            key={page.path}
          >
            {page.path === '/favorites' && (
              <ItemTick
                color={favoriteItemsQuantity === 0 ? Color.GRAY : Color.RED}
                pop={favoriteItemsPop}
              >
                {favoriteItemsQuantity}
              </ItemTick>
            )}
            <page.icon
              color={currentPage === page.path ? Color.WHITE : Color.DARK_GRAY}
              size={25}
              style={{ transition: 'none' }}
            />
            {page.name}
          </StyledLink>
        );
      })}
    </NavigationContainer>
  );
};

const ItemTick = styled.div<{ color: Color; pop: boolean }>`
  background-color: ${({ color }) => color};
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  color: white;
  top: -5px;
  left: -5px;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;

  ${({ pop }) => pop && cartTickPopAnimation}
`;

const CartButton = styled.div<{ disabled: boolean }>`
  position: relative;
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
  font-size: 1.5rem;
  color: ${Color.DARK_GRAY};
  cursor: pointer;
  text-decoration: none;
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);

  &:hover {
    background-color: ${Color.ACCENT};
    transform: scale(1.05);
  }

  ${({ disabled }) =>
    disabled &&
    `
      filter: grayscale(1) opacity(0.5) brightness(0.8);
      color: ${Color.WHITE};
      cursor: not-allowed;
      &:hover {
        background-color: inherit;
        transform: none;
    `}
`;

// currentPage prefixed with $ to avoid conflict with the prop name
const StyledLink = styled(Link)<{ $currentPage: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  font-size: 1.5rem;
  color: ${Color.DARK_GRAY};
  cursor: pointer;
  text-decoration: none;
  border-radius: 10px;

  &:hover {
    background-color: ${Color.LIGHT_GRAY};
  }

  ${({ $currentPage }) =>
    $currentPage &&
    `
      background-color: ${Color.GRAY};
      color: ${Color.WHITE};
      cursor: default;
      &:hover {
        background-color: ${Color.GRAY};
      }
    `}
`;

const NavigationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
  padding: 2rem 1rem 0 1rem;

  @media (min-width: 1024px) {
    padding: 190px 2rem 0 2rem;
  }
`;

import { algoliasearch, Hit } from 'algoliasearch';
import { Product } from '../domain/models/ProductDTO';
import { ScreenSize, useScreenSize } from './useScreenSize';

const algolia = {
  appId: process.env.REACT_APP_ALGOLIA_APP_ID ?? '',
  searchKey: process.env.REACT_APP_ALGOLIA_SEARCH_KEY ?? ''
};

const client = algoliasearch(algolia.appId, algolia.searchKey);

const INDEX_NAME = 'teniski';

const { REACT_APP_TENISKI_API_BASE_URL } = process.env;

interface SearchProps {
  searchTerm: string;
  currentPage: number;
  numberOfPages: number;
}

const mapHitsToProducts = (hits: Hit[]): Product[] =>
  // eslint-disable-next-line
  hits.map((hit: any) => ({
    id: hit.objectID,
    createdAt: hit.createdAt,
    title: hit.title,
    description: hit.description,
    thumbnail: hit.thumbnail,
    images: hit.images,
    price: hit.price,
    sizes: hit.sizes,
    labels: hit.labels
  }));

export const useAlgolia = () => {
  const screenSize = useScreenSize();
  const isSmallScreen = screenSize === ScreenSize.SMALL;

  const search = async (props: SearchProps) => {
    const { searchTerm, currentPage, numberOfPages } = props;

    if (currentPage >= numberOfPages) {
      return undefined;
    }

    const queryResponse = await client.searchSingleIndex({
      indexName: INDEX_NAME,
      searchParams: {
        query: searchTerm,
        hitsPerPage: isSmallScreen ? 8 : 9,
        page: currentPage
      }
    });

    const products = mapHitsToProducts(queryResponse.hits);

    return {
      products,
      page: queryResponse.page,
      nbPages: queryResponse.nbPages
    };
  };

  const indexProduct = async (product: Product) => {
    try {
      const res = await fetch(
        `${REACT_APP_TENISKI_API_BASE_URL}/algolia/indexProduct`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(product)
        }
      );

      const data = await res.json();
      console.log(data);
    } catch (error) {
      console.error('Error indexing products:', error);
    }
  };

  const deleteProduct = async (productId: string) => {
    try {
      const res = await fetch(
        `${REACT_APP_TENISKI_API_BASE_URL}/algolia/deleteProduct`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ id: productId })
        }
      );

      const data = await res.json();
      console.log(data);
    } catch (error) {
      console.error('Error deleting record:', error);
    }
  };

  return { search, indexProduct, deleteProduct };
};

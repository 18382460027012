import { useEffect } from 'react';
import { useCustomNavigate } from './useCustomNavigate';
import { useAppDispatch, useAppSelector } from './useRedux';
import { toast } from 'react-toastify';
import { favoritesActions } from '../store/favoritesSlice';
import { Product } from '../domain/models/ProductDTO';
// import styled from 'styled-components';
import { getLocalFavorites } from '../store/utils';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase/firebaseConfig';

export const useFavorites = () => {
  const dispatch = useAppDispatch();
  const navigate = useCustomNavigate();
  const favorites = useAppSelector((state) => state.favorites);

  const navigateToFavorites = () => navigate('/favorites');

  const setFavorites = () => {
    const favorites = getLocalFavorites();
    dispatch(favoritesActions.setFavorites(favorites));
  };

  useEffect(() => {
    setFavorites();
  }, []);

  const checkFavorites = async () => {
    const favorites = getLocalFavorites();
    const validFavorites: Product[] = [];

    console.log('favorites', favorites);
    for (const favorite of favorites) {
      const docRef = doc(db, 'products', favorite.id);
      const productDoc = await getDoc(docRef);
      console.log('productDoc', productDoc);

      if (productDoc.exists()) {
        validFavorites.push(favorite);
      }
    }

    dispatch(favoritesActions.setFavorites(validFavorites));
    localStorage.setItem('favorites', JSON.stringify(validFavorites));
  };

  const getIsFavorite = (product: Product): boolean =>
    !!favorites.find((item: Product) => item.id === product.id);

  const addToFavorites = (product: Product) => {
    dispatch(favoritesActions.addToFavorites(product));
    return toast.success('Продуктът беше добавен в любими', {
      onClick: navigateToFavorites
      // icon: (
      //   <ToastIcon
      //     src={require('../../../assets/images/broken-heart-icon.png')}
      //   />
      // )
    });
  };

  const removeFromFavorites = (product: Product) => {
    dispatch(favoritesActions.removeFromFavorites(product));
    return toast.success('Продуктът беше премахнат от любими', {
      onClick: navigateToFavorites
      // icon: (
      //   <ToastIcon src={require('../../../assets/images/red-heart-icon.png')} />
      // )
    });
  };

  return { checkFavorites, getIsFavorite, addToFavorites, removeFromFavorites };
};

// const ToastIcon = styled.img`
//   width: 24px;
// `;

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Color } from '../../assets/constants';
import { Product } from '../../domain/models/ProductDTO';
import { useProducts } from '../../hooks/useProducts';
import { ProductList } from '../../components/features/home/ProductList';
import { ActivityIndicator } from '../../components/common/ActivityIndicator';
import { UpdateProductContainer } from './UpdateProductContainer';
import { EdittableAndSelectableItems } from '../../components/common/EdittableAndSelectableItems';
import { Label, useLabels } from '../../hooks/useLabels';
import { EmptyProductList } from '../../components/common/EmptyList';
import { Input } from '../../components/common/Input';

export const ManageProductsContainer = () => {
  const [searchValue, setSearchValue] = useState('');
  const [labels, setLabels] = useState<Label[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [selectedLabelId, setSelectedLabelId] = useState<string>();
  const [selectedProductId, setSelectedProductId] = useState<string | null>(
    null
  );

  const { getLabels, isFetchingLabels } = useLabels();
  const {
    getAllProducts,
    getProductsByLabel,
    isLoading: isFetchingProducts
  } = useProducts();

  const setProductsFromFirebase = async (labelId?: string) => {
    if (labelId) {
      const products = await getProductsByLabel(labelId);
      return setProducts(products);
    }

    const { products } = await getAllProducts();
    return setProducts(products);
  };

  const setLabelsFromFirebase = async () => {
    const labels = await getLabels();

    const unlabelledLabel: Label = {
      id: 'unlabelled',
      name: 'UNLABELLED',
      index: 0
    };

    setLabels([unlabelledLabel, ...labels]);
  };

  useEffect(() => {
    setLabelsFromFirebase();
  }, []);

  useEffect(() => {
    setProductsFromFirebase(selectedLabelId);
  }, [selectedLabelId]);

  const handleSearch = async () => {
    if (searchValue === '') {
      return setProductsFromFirebase(selectedLabelId);
    }

    const filteredProducts = products.filter((product) =>
      product.title.toLowerCase().includes(searchValue.toLowerCase())
    );

    setProducts(filteredProducts);
  };

  const handleLabelSelection = (labelId: string) => {
    if (labelId === selectedLabelId) {
      return setSelectedLabelId(undefined);
    }

    setSearchValue('');
    setSelectedLabelId(labelId);
  };

  const handleProductSelection = (productId: string) => {
    setSelectedProductId(productId);
  };

  const handleBackToAllProducts = () => setSelectedProductId(null);

  return (
    <Wrapper>
      <Header>
        <Title>Manage products</Title>
        {selectedProductId && (
          <BackLink onClick={handleBackToAllProducts}>
            Back to all products
          </BackLink>
        )}
      </Header>
      {!selectedProductId && (
        <Input
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          onEnterKey={handleSearch}
        />
      )}
      {!selectedProductId && isFetchingLabels && (
        <ActivityIndicatorWrapper>
          <ActivityIndicator size={100} color={Color.ACCENT} />
        </ActivityIndicatorWrapper>
      )}
      {!selectedProductId && !isFetchingLabels && (
        <EdittableAndSelectableItems
          items={labels}
          selectedItemIds={selectedLabelId ? [selectedLabelId] : []}
          handleSelectItem={(labelId) => handleLabelSelection(labelId)}
          isFetchingItems={isFetchingLabels}
          selective
        />
      )}
      {!selectedProductId && isFetchingProducts && (
        <ActivityIndicatorWrapper>
          <ActivityIndicator size={100} color={Color.ACCENT} />
        </ActivityIndicatorWrapper>
      )}
      {!selectedProductId && !isFetchingProducts && (
        <ProductList
          products={products}
          cols={5}
          onSelectProductToEdit={handleProductSelection}
          allProductsHaveBeenFetched={true}
        />
      )}
      {!selectedProductId && selectedLabelId && products.length === 0 && (
        <EmptyProductList textColor={Color.WHITE} />
      )}
      {selectedProductId && (
        <UpdateProductContainer
          productId={selectedProductId}
          handleBackToAllProducts={handleBackToAllProducts}
        />
      )}
    </Wrapper>
  );
};

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
`;

const BackLink = styled.p`
  color: ${Color.ACCENT};
  font-size: 12px;
  cursor: pointer;
`;

const Title = styled.p`
  font-size: 24px;
  color: ${Color.WHITE};
`;

const ActivityIndicatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
`;

const Wrapper = styled.div`
  padding-top: 20px;
  min-height: 500px;
`;

import React from 'react';
import { useCustomNavigate } from '../hooks/useCustomNavigate';
import { useAppDispatch } from '../hooks/useRedux';
import { cartActions } from '../store/cartSlice';
import { Navigate, useLocation } from 'react-router';
import { ThankYouContainer } from '../containers/thankYou/ThankYouContainer';

export const ThankYouPage = () => {
  const navigate = useCustomNavigate();
  const dispatch = useAppDispatch();
  const { state } = useLocation();

  if (!state || !state.fromCheckout || !state.orderNumber) {
    return <Navigate to="/" />;
  }

  dispatch(cartActions.clearCart());

  const onGoBack = () => navigate('/');

  return (
    <ThankYouContainer orderNumber={state.orderNumber} onGoBack={onGoBack} />
  );
};

import { configureStore } from '@reduxjs/toolkit';
import { cartSlice } from './cartSlice';
import { searchSlice } from './searchSlice';
import { labelsSlice } from './labelsSlice';
import { favoritesSlice } from './favoritesSlice';
import { configSlice } from './configSlice';

export const store = configureStore({
  reducer: {
    cart: cartSlice.reducer,
    favorites: favoritesSlice.reducer,
    search: searchSlice.reducer,
    labels: labelsSlice.reducer,
    appConfig: configSlice.reducer
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
